<template>
  <div class="voucher_items_view">
    <div class="l">
      <el-input v-model="filterText" placeholder="请输入" clearable style="width: 330px" suffix-icon="el-icon-search" />
      <div class="tree_box">
        <el-tree
          ref="tree"
          default-expand-all
          highlight-current
          :data="treeData"
          :props="{
            children: 'children',
            label: 'projectName'
          }"
          @node-click="handleNodeClick"
          :filter-node-method="filterNode"
        ></el-tree>
      </div>
    </div>
    <div class="r">
      <div class="content-select" style="box-shadow: none">
        <div class="search_box">
          <el-form ref="filterForm" :model="queryParams" inline label-width="80px">
            <el-row class="row-first">
              <el-form-item label="项目编码" prop="projectCode">
                <el-input v-model="queryParams.projectCode" placeholder="请输入" clearable />
              </el-form-item>
              <el-form-item label="项目名称" prop="projectName">
                <el-input v-model="queryParams.projectName" placeholder="请输入" clearable @keyup.enter.native="handleQuery" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleQuery">查询</el-button>
                <el-button type="default" @click="resetQuery">重置</el-button>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="content-main">
        <div class="btn_box">
          <el-button type="primary" @click="handleBatch('add')">添加项目</el-button>
          <el-button @click="importVisible = true">导入项目</el-button>
          <el-button @click="handleBatch('update')">同步项目</el-button>
          <el-button @click="handleBatch('push')">推送</el-button>
        </div>
        <div class="content-table">
          <el-table :data="records" ref="table" stripe :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelection" border v-loading="loading">
            <el-table-column type="selection" width="50" fixed="left" />
            <el-table-column label="序号" type="index" width="80" />
            <el-table-column label="项目编码" prop="projectCode" :show-overflow-tooltip="true" />
            <el-table-column label="项目名称" prop="projectName" />
            <el-table-column label="推送状态" width="150">
              <template slot-scope="scope">
                {{ scope.row.pushStatus == 0 ? '未推送' : scope.row.pushStatus == 1 ? '推送中' : scope.row.pushStatus == 2 ? '已推送' : '推送失败' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-button size="small" type="text" @click="handleTableOpt(scope.row, 'edit')">编辑</el-button>
                <el-button size="small" type="text" @click="handleTableOpt(scope.row, 'del')">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="content-page">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[15, 30, 50, 100]" :page-size="queryParams.size" layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>

    <el-dialog width="400px" :visible.sync="visiable" :title="dialogType == 'add' ? '添加项目' : '编辑项目'">
      <div class="dialog_con">
        <el-form ref="form" :model="form" label-width="80px" label-position="right">
          <!--          <el-form-item label="上级项目" required>
            <el-cascader popper-class="cus_cascader" :options="options" :show-all-levels="false" style="width: 280px"></el-cascader>
          </el-form-item>-->
          <el-form-item label="上级项目" required>
            <el-select :disabled="dialogType == 'edit'" ref="cusSelect" :popper-append-to-body="false" popper-class="popper_select" v-model="form.curChooseUp" placeholder="请选择" style="width: 200px">
              <el-option value="">
                <el-tree
                  ref="tree"
                  default-expand-all
                  highlight-current
                  :data="treeData"
                  node-key="id"
                  :props="{
                    children: 'children',
                    label: 'projectName'
                  }"
                  @node-click="handleNodeDialogClick"
                  :filter-node-method="filterNode"
                ></el-tree>
              </el-option>

              <!-- <el-option v-for="item in vouProjectList" :key="item.id" :label="item.projectName" :value="item.id"> </el-option> -->
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="项目编码" required>
            <el-input v-model="form.name" placeholder="请输入上级编码" style="width: 280px"></el-input>
          </el-form-item>-->
          <el-form-item label="项目名称" required>
            <el-input v-model="form.projectName" placeholder="请输入项目名称" style="width: 200px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="button-footer">
        <el-button type="default" @click="visiable = false" class="button-left">取消</el-button>
        <el-button type="primary" @click="handleDialog">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog append-to-body title="项目导入" :visible.sync="importVisible" width="500px" :show-close="false">
      <project-import @handReloadCustomerList="handReloadCustomerList" @handleCancelImportDialog="importVisible = false"></project-import>
    </el-dialog>

    <!-- 删除 -->
    <el-dialog width="300px" append-to-body :visible.sync="delVisiable" title="提示">
      <div>确定要删除该条数据吗？</div>
      <!--<div style="margin: 4px 0">
        <el-checkbox v-model="isNeed">同步u+删除</el-checkbox>
      </div>-->
      <div slot="footer" class="button-footer" style="text-align: center">
        <el-button type="default" @click="delVisiable = false" class="button-left">取消</el-button>
        <el-button type="primary" @click="handleDelDialog">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tableStyle } from '@/util/mixins';
import { getProjectTree, projectList, projectEdit, projectDel, projectSync, projectPush, projectImport, projectAll, projectAdd, selectProject } from '@/service/voucher';
import ProjectImport from './ProjectImport';

export default {
  name: 'VouXmgl',
  components: { ProjectImport },
  mixins: [tableStyle],
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  data() {
    return {
      delId: '',
      isNeed: false,
      delVisiable: false,
      filterText: '',
      // 查询参数
      queryParams: {
        page: 1,
        size: 15,
        currentNodeId: '',
        projectCode: '',
        projectName: ''
      },
      records: [],
      loading: false,
      importVisible: false, // 导入弹窗
      projectName: '',
      vouProjectList: [],
      proVouProjectList: [],
      total: 0,
      dialogType: 'add', //add edit
      paging: {
        total: 0,
        size: 15,
        current: 1
      },
      pageSizes: [15, 30, 50, 100],
      visiable: false,
      treeData: [],
      form: {
        pId: '',
        projectName: '',
        curChooseUp: '',
        id: '',
        projectId:''
      },
      options: [],
      params: {
        ids: ''
      }
    };
  },
  created() {
    this.getList();
    this._getProjectTree();
    this._vouProjectList();
  },

  methods: {
    // 删除
    handleDelDialog() {
      projectDel(this.delId).then((res) => {
        this.delVisiable = false;
        if (res.success) {
          this.toast('操作成功', 'success');
          this.resetQuery();
          this._getProjectTree();
        }
      });
    },
    handReloadCustomerList() {
      this.resetQuery();
      this._getProjectTree();
      this.importVisible = false;
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.params.ids = value.map((item) => item.id);
    },

    handleNodeDialogClick(item) {
      this.form.curChooseUp = item.projectName;
      this.form.pId = item.id;
      this.$refs.cusSelect.handleClose();
    },

    _getProjectTree() {
      getProjectTree().then((res) => {
        console.log(res);
        if (res.success) {
          this.treeData = res.data;
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.projectName.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      // console.log(data);
      this.queryParams.currentNodeId = data.fullId;
      this.getList();
    },

    getList() {
      this.loading = true;
      projectList(this.queryParams).then(({ success, data }) => {
        this.loading = false;
        const { records, total } = data;
        if (success) {
          this.records = records;
          this.total = total;
        }
      });
    },
    /** 搜索 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置 */
    resetQuery() {
      this.queryParams = {
        page: 1,
        size: 15,
        currentNodeId: '',
        projectCode: '',
        projectName: ''
      };
      this.getList();
    },
    // 导入
    /*handleImport() {
      this.$message({ message: '恭喜你，这是一条成功消息', type: 'success' });
    },*/
    // 批量
    async handleBatch(type) {
      switch (type) {
        case 'add':
          this.visiable = true;
          this.dialogType = type;
          this.form.projectName = '';
          this.form.curChooseUp = '';
          break;
        case 'push':
          // 判断this.params.ids是否为空
          if (this.params.ids.length == 0) {
            this.toast('请勾选需要推送的项目信息', 'warning');
            return;
          }
          projectPush(this.params).then((res) => {
            const { success } = res;
            if (success) {
              this.toast('推送项目信息成功', 'success', () => this.resetQuery());
            }
          });

          break;
        case 'import':
          projectImport().then((res) => {
            res.success && this.toast('操作成功', 'success');
            this.resetQuery();
          });
          break;
        case 'update':
          projectSync().then((res) => {
            res.success && this.toast('操作成功', 'success');
            this.resetQuery();
          });
          break;
        default:
          break;
      }
      // this.$message.error('错了哦，这是一条错误消息');
    },
    // 操作
    handleTableOpt(row, type) {
      switch (type) {
        case 'edit':
          this.visiable = true;
          this.dialogType = type;
          selectProject(row.id).then((res) => {
            this.form.projectName = res.data.projectName;
            this.form.curChooseUp = res.data.parentProjectName;
            this.form.id = res.data.id;
            this.form.projectId = res.data.projectId;
            this.visiable = true;
          });
          break;
        case 'del':
          this.delVisiable = true;
          this.delId = row.id;
          // this.$confirm('确定要删除该条项目吗？', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // })
          //   .then(() => {
          //     projectDel(row.id).then((res) => {
          //       this.visiable = false;
          //       if (res.success) {
          //         this.toast('操作成功', 'success');
          //         this.resetQuery();
          //         this._getProjectTree();
          //       }
          //     });
          //   })
          //   .catch(() => {
          //     this.visiable = false;
          //   });

          break;
        default:
          break;
      }
    },
    handleSizeChange(val) {
      this.queryParams.page = 1;
      this.queryParams.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.queryParams.page = val;
      this.getList();
    },
    handleAdd() {
      this.dialogType = 'add';
      this.visiable = true;
      this.form.projectName = '';
      this.form.pId = '';
    },
    async _vouProjectList() {
      const { data } = await projectAll();
      this.vouProjectList = data;
    },
    // 添加或者编辑
    handleDialog: function () {
      // 新增模板
      this.dialogType == 'add'
        ? projectAdd(this.form).then((res) => {
            this.visiable = false;
            this.getList();
            this._getProjectTree();
            res.success && this.toast('操作成功！', 'success');
            this.form.projectName = '';
            this.form.pId = '';
          })
        : projectEdit(this.form).then((res) => {
            this.visiable = false;
            this.resetQuery();
            this._getProjectTree();
            res.success && this.toast('操作成功！', 'success');
          });

      /*this.$confirm('哈哈哈', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
          })
          .catch(() => {
            this.toast('取消清空', 'warning');
          });*/
    }
  }
};
</script>
<style lang="scss" scoped>
.voucher_items_view {
  display: flex;
  background: #fff;
  .l {
    width: 370px;
    padding: 0 20px 0;
    margin: 20px 0;
    border-right: 1px solid #e9e9e9;

    .el-input {
      margin-bottom: 20px;
    }
  }
  .r {
    flex: 1;
    padding: 0 20px;
    .content-main {
      margin: 0;
      padding: 10px 0;
    }
  }
  .search_box {
    padding: 20px 0 5px;
  }
  .btn_box {
    padding-bottom: 10px;
  }
  .el-button--text {
    padding: 0 !important;
  }
  .dialog_con {
    padding: 0 20px;
    .el-cascader {
      .el-input {
        width: 280px !important;
      }
    }
    .el-form {
      .el-form-item {
        margin-bottom: 26px;
      }
    }
  }
}
</style>
<style lang="scss">
.dialog_con {
  .el-form {
    .el-form-item {
      .el-form-item__content {
        .el-cascader .el-input {
          width: 280px !important;
        }
      }
    }
  }
}
.popper_select {
  .el-select-dropdown__item {
    height: 200px;
    overflow-y: auto;
  }
  .el-tree,
  .el-select-dropdown__item {
    background: transparent;
  }
}
</style>
